<template>
  <div id="test">
      <jodit-editor v-model="content"  :config="editorConfig"/>
  </div>
</template>

<script>
import 'jodit/build/jodit.min.css';
import { JoditEditor } from 'jodit-vue';
import {
  commify,
  apiCall,
} from '../../common/utils';

export default {
  name: 'Test',
  components: { JoditEditor },

  data () {
    return {
      content: '<h1>Hello Jodit Vue</h1>',
      buttons: [
        'bold', 'strikethrough', 'underline', 'italic', '|',
        'ul', 'ol', '|',
        'outdent', 'indent',  '|',
        'font', 'fontsize', 'brush', 'paragraph', '|',
        'image', 'video', 'table', 'link', '|',
        'align', 'undo', 'redo', '|',
        'hr', '|',
        'fullsize', 'print', '|', 
        'source'
        // 'about'
      ],
    }
  },
  async created() {
  },
  computed: {
    editorConfig() {
      const token = localStorage.accessToken;
      // console.log("token ==========> ", token);
      
      const protocol = window.location.protocol; // 현재 프로토콜
      const host = window.location.hostname; // 현재 접속 호스트
      const wlPort = window.location.port; // 현재 접속 포트
      const port = (wlPort==="8888") ? '8080':'443';

      const apiServer = `${protocol}//${host}:${port}`;

      // console.log("editorConfig : ==================> ", `${this.apiUrl}/api/spec-shop/image/upload`);
      return {
        uploader: {
          url: `${apiServer}/api/spec-shop/image/upload`,
          format: 'json',
          method: 'POST',
          headers: { 
            'Authorization': token,
          },
          baseurl: `${apiServer}`,

          isSuccess: function (resp) {
            console.log("isSuccess :: >>>>>>>>>>>>>> ", resp);
            return resp.data.code === 220;
          },
          process: function (resp) {
            return resp.data;
          },
        },
        placeholder : '상품 상세내용을 입력해주세요 ..',
        minHeight: '500px',
      };
    },
  },
  methods: {
  },

}
</script>

<style>
.jodit-toolbar-button_file, .jodit-ui-group__file {
  /* jodit 파일첨부 기능 가리기 */
  display: none !important;
}
</style>